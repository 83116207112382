import React from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
// import { navigate } from "gatsby"

import { LayoutQuoting, Title, SectionCenter } from "../components"
import InCAFollowUp from "../components/GMSPurchase/InCAFollowUp"
// import { getCurrentPath } from "../redux/actions"
// import { mq } from "../utils/media-query"

const GetQuotePurpose = ({ location, getCurrentPath }) => {
  // const onGoBack = () => {
  //   navigate("/")
  // }
  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <Title title="testing element..." fontColor="var(--clr-primary-300)" />
        <SectionCenter>
          <InCAFollowUp />
        </SectionCenter>
      </section>
    </LayoutQuoting>
  )
}

export default connect(null, null)(GetQuotePurpose)
